import { graphql } from "gatsby";
import React from "react";
import { Layout } from "./Layout";
import Post from "./Post";

type Props = {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        slug: string;
        date: string;
        title: string;
        tags: string[];
        product_link: string;
        product_link_name?: string;
        featured_video: string;
        featured_video_id: string;
        featured_video_start?: number;
        featured_video_host: string;
        author: string;
        referrer_url?: string;
        referrer_name?: string;
        html: string;
      };
    };
  };
};

const CatalogArticle: React.FC<Props> = ({
  data: {
    markdownRemark: { html, frontmatter },
  },
}) => {
  const {
    title,
    slug,
    date,
    tags,
    product_link,
    product_link_name,
    featured_video,
    featured_video_id,
    featured_video_start,
    featured_video_host,
    referrer_url,
    referrer_name,
    author,
  } = frontmatter;

  return (
    <Layout title={`${title} • Interaction Compendium`}>
      <Post
        key={slug + title}
        slug={slug}
        date={date}
        title={title}
        tags={tags}
        product_link={product_link}
        product_link_name={product_link_name}
        featured_video={featured_video}
        featured_video_id={featured_video_id}
        featured_video_start={featured_video_start}
        featured_video_host={featured_video_host}
        referrer_url={referrer_url}
        referrer_name={referrer_name}
        author={author}
        html={html}
      />
    </Layout>
  );
};

export default CatalogArticle;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        date(formatString: "DD MMMM, YYYY")
        title
        featured_video
        featured_video_id
        featured_video_start
        featured_video_host
        tags
        author
        product_link
        referrer_url
        referrer_name
      }
    }
  }
`;
